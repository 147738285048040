import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'projects/dialog/src/environments/environment';
import { BehaviorSubject, Observable, } from 'rxjs';
import { LocalPackage } from '../model/local-package';
import { AppUtils } from '../utilities/AppUtils';

@Injectable({
  providedIn: 'root'
})
export class SoftwareService {
  private readonly gUrl = environment.apiUrl + '/';
  public selectedPackage: BehaviorSubject<LocalPackage> = new BehaviorSubject(undefined);

  constructor(public appUtil: AppUtils, private http?: HttpClient) {}

  public getPackageList(country: string): Observable<LocalPackage[]> {
    const url = `${this.gUrl}v1/customer/package/local`;
    return this.http.get<LocalPackage[]>(url, { params: { country: country } });
  }

  public getPackageDetails(localPackageId: string): Observable<LocalPackage> {
    const url = `${this.gUrl}v1/customer/package/local/` + localPackageId;
    return this.http.get<LocalPackage>(url, {});
  }

  public downloadPackage(localPackageId: string, filename: string): Observable<Response> {
    const url = `${this.gUrl}v1/customer/package/local/${localPackageId}/file`;
    return this.http.get<Response>(url,  { params: { filename: filename } });
  }
}
