import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { LocalPackage } from '../../model/local-package';

export interface PeriodicElement {
  version: string;
  packageName: string;
  status: 'ADDED' | 'PUBLISHED';
  localPackageDescription: string;
  countries: string[];
}

export enum SelectType {
  single,
  multiple
}

@Component({
  selector: 'dl-software-table',
  templateUrl: './software-table.component.html',
  styleUrls: ['./software-table.component.scss']
})
export class SoftwareTableComponent {
  @Input() set newSoftware(newSoftware: boolean) {
    this.displayedColumns = newSoftware
      ? ['localPackageName', 'localPackageDescription', 'status', 'actions']
      : ['localPackageName', 'localPackageDescription'];
  }

  @Input()
  dataSource = new MatTableDataSource<LocalPackage>([]);

  displayedColumns: string[] = [];
  selection = new SelectionModel<LocalPackage>(true, []);
  displayType = SelectType.single;

  @Output()
  public selectSoftware: EventEmitter<LocalPackage> = new EventEmitter<LocalPackage>();

  public constructor() {}

  public selectHandler(row: LocalPackage) {
    if (!this.selection.isSelected(row)) {
      this.selection.clear();
    }
    this.selectSoftware.emit(!this.selection.isSelected(row) ? row : undefined);
    this.selection.toggle(row);
  }
}
