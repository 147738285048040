<ng-container [ngSwitch]="(view$ | async)">
  <ng-container *ngSwitchCase="View.DesktopLoggedIn">
    <div class="full-height">
      <dl-double-global-area title="DiaLog Portal" isDesktop="true"></dl-double-global-area>
      <dl-loading-bar></dl-loading-bar>
      <router-outlet></router-outlet>
    </div>
    <dl-footer></dl-footer>
  </ng-container>

  <dl-mobile-sidenav *ngSwitchCase="View.Mobile">
    <ng-container ngProjectAs="[page]">
      <dl-double-global-area title="DiaLog Portal"></dl-double-global-area>
      <dl-loading-bar></dl-loading-bar>
      <router-outlet></router-outlet>
    </ng-container>
  </dl-mobile-sidenav>

  <ng-container *ngSwitchCase="View.DesktopLoggedOut">
    <div class="full-height">
      <dl-double-global-area title="DiaLog Portal" isDesktop="true"></dl-double-global-area>
      <dl-loading-bar></dl-loading-bar>
      <router-outlet></router-outlet>
    </div>
    <dl-footer></dl-footer>
  </ng-container>
</ng-container>

<!-- Translate text for usage in .ts files https://angular.io/guide/i18n#translate-text-without-creating-an-element -->
<div [hidden]="true">
  <ng-container #eserviceAvailabilityError i18n="Eservice availabilities error message | Message to be displayed when eservice availabilities API returns an error@@core.availabilities-service.loadingFailure" data-translation-id="core.availabilities-service.loadingFailure">Could not load eService Availabilities</ng-container>
  <ng-container #userEservicesError i18n="User assigned eservice availabilities error message | Message to be displayed when user eservice availabilities API returns an error@@core.user-e-services-service.loadingFailure" data-translation-id="core.user-e-services-service.loadingFailure">User assigned eServices could not be loaded</ng-container>
  <ng-container #noLearnMoreContent i18n="Learn more error message | Message to be displayed when the API returns null content for learn more@@features.learn-more-dialog.learn-more-no-content" data-translation-id="features.learn-more-dialog.learn-more-no-content">Could not load contents for learn more</ng-container>
  <ng-container #requestSuccess i18n="Request eservice success message | Message to be displayed when an user can successfully request an eservice@@core.user-e-services-service.requestSuccess" data-translation-id="core.user-e-services-service.requestSuccess">Access requested</ng-container>
  <ng-container #requestFail i18n="Request eservice failure message | Message to be displayed when an user can not request an eservice@@core.user-e-services-service.requestFail" data-translation-id="core.user-e-services-service.requestFail">Request not successful</ng-container>
  <ng-container #liveChatError i18n="Live chat error message | Message to be displayed when live chat cannot be enabled@@header.live-chat.live-chat-error" data-translation-id="header.live-chat.live-chat-error">Live chat cannot be enabled</ng-container>
  <ng-container #favoriteAdded i18n="eService marked as favorite | Message to be displayed when an eservice is marked as favorite@@core.user-e-services-service.favoriteAdded" data-translation-id="core.user-e-services-service.favoriteAdded">eservice marked as favorite</ng-container>
  <ng-container #favoriteRemoved i18n="eService unmarked as favorite | Message to be displayed when an eservice is unmarked as favorite@@core.user-e-services-service.favoriteRemoved" data-translation-id="core.user-e-services-service.favoriteRemoved">eservice removed from favorites</ng-container>
  <ng-container #favoriteError i18n="Error in favorites | Message to be displayed when an eservice cannot be marked or unmarked as favorite@@core.user-e-services-service.favoriteError" data-translation-id="core.user-e-services-service.favoriteError">Could not mark/unmark favorite</ng-container>
  <ng-container #changeGroupsError i18n="Change groups error message|When an eService assignment to a different group failed@@groups.groups-service.changeGroupsError" data-translation-id="groups.groups-service.changeGroupsError">Groups not changed</ng-container>
  <ng-container #changeGroupsSuccess i18n="Change groups success message|When an eService assignment to a different group succeeded@@groups.groups-service.changeGroupsSuccess" data-translation-id="groups.groups-service.changeGroupsSuccess">Groups changed</ng-container>
  <ng-container #createGroupError i18n="Create group error message|When creation of new eService group failed@@groups.groups-service.createGroupError" data-translation-id="groups.groups-service.createGroupError">Group not created</ng-container>
  <ng-container #createGroupSuccess i18n="Change groups success message|When creation of new eService group succeeded@@groups.groups-service.createGroupSuccess" data-translation-id="groups.groups-service.createGroupSuccess">Group created</ng-container>
  <ng-container #deleteGroupError i18n="Delete group error message|When deletion of eService group failed@@groups.groups-service.deleteGroupError" data-translation-id="groups.groups-service.deleteGroupError">Group not deleted</ng-container>
  <ng-container #deleteGroupSuccess i18n="Delete group success message|When deletion of eService group succeeded@@groups.groups-service.deleteGroupSuccess" data-translation-id="groups.groups-service.deleteGroupSuccess">Group deleted</ng-container>
  <ng-container #editGroupError i18n="Edit group error message|When an edit of an eService group failed@@groups.groups-service.editGroupError" data-translation-id="groups.groups-service.editGroupError">Group not edited</ng-container>
  <ng-container #editGroupSuccess i18n="Edit group success message|When an edit of an eService group succeeded@@groups.groups-service.editGroupSuccess" data-translation-id="groups.groups-service.editGroupSuccess">Group edited</ng-container>
  <ng-container #getGroupsError i18n="Load groups error message|When the eService groups cannot be loaded@@groups.groups-service.getGroupsError" data-translation-id="groups.groups-service.getGroupsError">Groups not loaded</ng-container>
  <ng-container #getHelpTicketSuccess i18n="Show help ticket success message|When help ticket successfully submitted@@get-help.get-help-ticket.submitTicketSuccess" data-translation-id="get-help.get-help-ticket.submitTicketSuccess">Your ticket has been submitted to REXIS. Please check REXIS for updates on your inquiry.</ng-container>
  <ng-container #getHelpTicketRequestTypeTechnicalIssue i18n="Type of request option Technical Issue|Type of request option Technical Issue@@get-help.get-help-ticket.requestTypeTechnicalIssue" data-translation-id="get-help.get-help-ticket.requestTypeTechnicalIssue">Technical issue</ng-container>
  <ng-container #getHelpTicketRequestTypeFeatureRequest i18n="Type of request option Feature request|Type of request option Feature request@@get-help.get-help-ticket.requestTypeFeatRequest" data-translation-id="get-help.get-help-ticket.requestTypeFeatRequest">Feature request</ng-container>
  <ng-container #getHelpTicketRequestTypeOther i18n="Type of request option Other|Type of request option Other@@get-help.get-help-ticket.requestTypeOther" data-translation-id="get-help.get-help-ticket.requestTypeOther">Other</ng-container>
</div>
