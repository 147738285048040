import { Component, OnDestroy, OnInit } from '@angular/core';
import { CountryLanguageService } from '@dialog-eservices-enablement/angular-components';
import { OneSnackBarService, OneSnackBarType } from '@one/angular-kit/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ModalService } from '../../../../core/services/modal.service';
import { LocalPackage, PackageType } from '../../model/local-package';
import { SoftwareService } from '../../services/software.service';
import { TermsAndConditionsModalComponent } from '../modals/terms-and-conditions-modal/terms-and-conditions-modal.component';

@Component({
  selector: 'dl-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  public disableButton = true;
  public isListLoaded: boolean = false;
  public isError = false;
  public assayList: LocalPackage[];
  public coreList: LocalPackage[];
  public selectedPackage: LocalPackage;
  private destroy$ = new Subject();

  constructor(
    private modalService: ModalService,
    private softwareService: SoftwareService,
    private countryLanguageService: CountryLanguageService,
    private snackBarService: OneSnackBarService
  ) {}

  ngOnInit(): void {
    this.softwareService
      .getPackageList(this.countryLanguageService.countryCode.toUpperCase())
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (packageList: LocalPackage[]) => {
          this.coreList = packageList.filter((localPackage: LocalPackage) => {
            return localPackage.type === PackageType.CORE;
          });
          this.assayList = packageList.filter((localPackage: LocalPackage) => localPackage.type === PackageType.ASSAY);
          this.isListLoaded = true;
        },
        error => {
          this.snackBarService.open('Could not retrive package list.', OneSnackBarType.ERROR);
          console.log(error);
          this.isError = true;
        }
      );
  }

  public openModal(): void {
    this.softwareService.selectedPackage.next(this.selectedPackage);
    this.modalService.showModal<any, any, any>(TermsAndConditionsModalComponent);
  }

  public setSelectedPackage(selectedPackage: LocalPackage) {
    this.selectedPackage = selectedPackage;
    this.disableButton = selectedPackage === undefined;
  }

  public checkIfNotPackagesAreNotEmpty(packageList: LocalPackage[]): boolean {
    return packageList !== undefined && packageList.length > 0;
  }

  public checkIfAnyPackagesAreAvaiable(): boolean {
    return !this.checkIfNotPackagesAreNotEmpty(this.coreList) && !this.checkIfNotPackagesAreNotEmpty(this.assayList) && this.isListLoaded;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
