<div class='software-download-dashboard-container'>
  <div class='software-download-dashboard--form-panel text-left'>
    <mat-card class='mat-card' style='padding: 50px'>
      <mat-card-content>
        <mat-card-title class='mat-card-title title'>
          cobas<sup>®</sup> liat Support: Software Update package
        </mat-card-title>
        <div class='software-download-dashboard__title--text mar-bot-0'>Ready to update your Software?
        </div>
        <div class='software-download-dashboard__title--text mar-top-0'>Choose the needed Software update package to begin the download.
        </div>
        <div *ngIf="!isError">
        <mat-tab-group *ngIf="isListLoaded">
          <mat-tab *ngIf="checkIfNotPackagesAreNotEmpty(coreList)" label='Core Software' active>
            <dl-software-table (selectSoftware)='setSelectedPackage($event)'
                               [newSoftware]='false' [dataSource]='coreList'></dl-software-table>
          </mat-tab>
          <mat-tab *ngIf="checkIfNotPackagesAreNotEmpty(assayList)" label='Assay Scripts'>
            <dl-software-table (selectSoftware)='setSelectedPackage($event)'
                               [newSoftware]='false' [dataSource]='assayList'></dl-software-table>
          </mat-tab>
        </mat-tab-group>
        <div *ngIf="checkIfAnyPackagesAreAvaiable()" >
          <h2 class="align-message">Currently, there are no software packages available for your country. Please, contact your local Roche support team for assistance.</h2>
        </div>
        <div class="loading-box"><mat-spinner *ngIf="!isListLoaded"></mat-spinner></div>
      </div>
      <div *ngIf="isError">
        <h2 class="align-message">There was a technical problem. Please, contact your local Roche Dialog support team for assistance</h2>
      </div>
      </mat-card-content>
      <div *ngIf="checkIfNotPackagesAreNotEmpty(coreList) || checkIfNotPackagesAreNotEmpty(assayList)" class='software-download-dashboard__buttons'>
        <button mat-raised-button color='primary' id='submitButton' (click)='openModal()' [disabled]='disableButton'>
          Continue
        </button>
      </div>
    </mat-card>
  </div>
</div>
