import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SoftwareDownloadRoutingModule } from './software-download-routing.module';
import { MaterialModule } from '../../material/material.module';
import * as softwareComponents from './components';
import { ReactiveFormsModule } from '@angular/forms';
import { AppUtils } from './utilities/AppUtils';

@NgModule({
  declarations: [softwareComponents.allComponents],
  imports: [CommonModule, SoftwareDownloadRoutingModule, MaterialModule, ReactiveFormsModule],
  providers: [AppUtils]
})
export class SoftwareDownloadModule {}
