<div class="download-instruction-page-container">
  <mat-vertical-stepper *ngIf="localPackageDetails !== undefined">
    <mat-step label="Prepare the USB flash drive" [editable]="true" [completed]="false" [state]="">
      <div class="x-text">
        <p>Prior to downloading and creating the SW or script installation USB key:</p>
        <ul class="ml-40">
          <li>Ensure your computer has up-to-date malware protection installed.</li>
          <li>Ensure the USB key has an allocation size of at least 4GB</li>
          <li>Ensure the USB key is minimum USB 2.0 compatible.</li>
        </ul>
      </div>

      <div class="x-text">
        <p>Note:</p>
        <ul class="ml-40">
          <li>It is recommended to use a pin-lockable USB flash drive if additional security is required</li>
          <li>Format the USB key using FAT32 format prior to copying the package onto it.</li>
          <li>
            Use this USB flash drive with the package only for installing or updating a a&nbsp;<b>cobas</b><sup>®</sup>&nbsp;liat&nbsp;analyzer.
          </li>
          <li>Do not try to open the items on the USB flash drive )e.g. Using a computer).</li>
        </ul>
      </div>
      <button mat-raised-button color="primary" matStepperNext>Continue</button>
    </mat-step>

    <mat-step label="Download the package and extract to the USB key" [completed]="false">
      <div class="x-text ">
        <ol>
          <li>
            The download is a self extracting <b>{{ localPackageDetails.packageAttachments[0]?.fileName }}</b> file containing the zipped software or
            script package
          </li>
        </ol>
        <p>&nbsp;</p>
        <p class="ml-40">
          Right Click the Update Package link below and save to your preferred location<b> (Do NOT save this file to your USB)</b>. For Internet
          Explorer, right click and select <i>Save Target As…</i> For other browsers select <i>Save Link As</i>...<br />
        </p>
        <p>
          <a *ngIf="!downloadUrl" mat-raised-button color="primary" class="ml-40 download-button" href="{{ downloadUrl }}" disabled="disabled">
            Download package</a
          >
          <a *ngIf="downloadUrl" mat-raised-button color="primary" class="ml-40 download-button" href="{{ downloadUrl }}"> Download package </a
          >&nbsp;&nbsp;
          <b
            ><span>{{ localPackageDetails.localPackageName }}</span></b
          >
        </p>
        <p>&nbsp;</p>
        <ol start="2">
          <li>Run the self extracting .exe package from your computer.</li>
        </ol>
        <p>&nbsp;</p>
        <p><img src="./assets/download-instructions/step2.png" class="img-cl" /></p>
        <p>&nbsp;</p>
        <ol start="3">
          <li>At the prompt for where to extract the package: <br /></li>
        </ol>
        <p>&nbsp;</p>
        <p><img src="./assets/download-instructions/step3.png" class="img-cl" /></p>
        <p>&nbsp;</p>
        <ol start="4">
          <li>Confirm that the USB key has the extracted files on it.</li>
        </ol>
        <p class="ml-40">Select the root directory of the prepared USB flash drive.</p>
        <p>&nbsp;</p>
        <p><img src="./assets/download-instructions/step4.png" class="img-cl" /></p>
        <p>&nbsp;</p>
        <ol start="5">
          <li>
            After installing the Software on the Liat, it can be activated by clicking link below.
          </li>
        </ol>
        <p>
          <a mat-raised-button color="primary" class="ml-40 download-button" href="https://liatactivation.dialog.roche.com/cobas-liat-dialog/" target="_blank">
            Activation Portal</a
          >
        </p>
      </div>
    </mat-step>
  </mat-vertical-stepper>
</div>
