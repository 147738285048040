import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthStoreService } from '@dialog-eservices-enablement/angular-components';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'dl-angular-boilerplate',
  templateUrl: './angular-boilerplate.component.html',
  styleUrls: ['./angular-boilerplate.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class AngularBoilerplateComponent implements OnInit {
  public cookieValue: string;
  public userLsIdToken: string;
  public userLsAccessToken: string;
  public userLsUserdata: string;
  public isLoggedIn = this.authStoreService.isLoggedIn;
  public constructor(private authStoreService: AuthStoreService, private cookieService: CookieService) {}
  public ngOnInit(): void {
    this.cookieValue = this.cookieService.get('OptanonConsent');

    for (let i = 0; i < localStorage.length; i++) {
      const lsKeyName: string = localStorage.key(i);
      if (lsKeyName.includes('.idToken')) {
        this.userLsIdToken = localStorage.getItem(lsKeyName);
      }
      if (lsKeyName.includes('.accessToken')) {
        this.userLsAccessToken = localStorage.getItem(lsKeyName);
      }
      if (lsKeyName.includes('.userData')) {
        this.userLsUserdata = localStorage.getItem(lsKeyName);
      }
    }
  }
  public ShowPopUp(): void{
    // Get the modal
    var img = document.getElementById("RequestSuccess");

    // Get the button that opens the modal
    var btn = document.getElementById("RequestMain");

    // When the user clicks on the button, open the modal
    img.style.display = "block";

  }

  public HidePopUp(): void{
    // Get the Image to be Shown
    var img = document.getElementById("RequestSuccess");

    // Get the button that opens the Image
    var btn = document.getElementById("RequestSuccess");

    // When the user clicks on <span> (x), close the modal
    img.style.display = "none";
  }

}
