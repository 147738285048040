import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OneSnackBarService, OneSnackBarType } from '@one/angular-kit/modal';
import { of, Subject, Subscription } from 'rxjs';
import { catchError, mergeMap, takeUntil } from 'rxjs/operators';
import { LocalPackage } from '../../model/local-package';
import { SoftwareService } from '../../services/software.service';

@Component({
  selector: 'dl-download-instruction-page',
  templateUrl: './download-instruction-page.component.html',
  styleUrls: ['./download-instruction-page.component.scss']
})
export class DownloadInstructionPageComponent implements OnInit, OnDestroy {
  constructor(private router: Router, private softwareService: SoftwareService, private snackBarService: OneSnackBarService) {}

  public localPackageDetails: LocalPackage;
  public downloadUrl: String;
  private destroy$ = new Subject();

  ngOnInit(): void {
    this.softwareService.selectedPackage
      .pipe(
        takeUntil(this.destroy$),
        mergeMap(selectedPackage => {

          if (selectedPackage === undefined || selectedPackage === null) {
            this.router.navigate(['/']); return of(null as LocalPackage);
          }
          return this.softwareService
              .getPackageDetails(selectedPackage.localPackageId)
              .pipe(takeUntil(this.destroy$), catchError(error => {
                this.snackBarService.open('Could not retrive package details.', OneSnackBarType.ERROR);
                console.log(error);
                return of(error);
              }))
        }),
        mergeMap(selectedPackageDetails => {
          this.localPackageDetails = selectedPackageDetails;
          if(selectedPackageDetails && selectedPackageDetails.packageAttachments?.length > 0){
            this.softwareService
            .downloadPackage(this.localPackageDetails.localPackageId, this.localPackageDetails.packageAttachments[0].fileName)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
              response => {
                const urlStr: string = response.body.toString();
                if (urlStr.startsWith('https:')) {
                  this.downloadUrl = urlStr;
                } else {
                  this.snackBarService.open('Could not retrive file url.', OneSnackBarType.ERROR);
                  console.error('Could not retrive file url.');
                }
              },
              error => {
                this.snackBarService.open('Could not retrive file url.', OneSnackBarType.ERROR);
                console.error(error);
              }
            );
          } else if(selectedPackageDetails){
            this.snackBarService.open('Package does not have attachment.', OneSnackBarType.ERROR);
            console.error('Package Does not have attachment.');
          }
          return of(selectedPackageDetails);
        })
      )
      .subscribe();
  }

  public downloadAttachment() {
    this.softwareService.downloadPackage(this.localPackageDetails.globalPackageId, this.localPackageDetails.packageAttachments[0].fileName);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
