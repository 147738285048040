<mat-table [dataSource]='dataSource' class='mat-elevation-z8'>

  <!-- Position Column -->
  <ng-container matColumnDef='localPackageDescription'>
    <mat-header-cell *matHeaderCellDef> Description</mat-header-cell>
    <mat-cell class="description-cell" *matCellDef='let element'> {{element.localPackageDescription}} </mat-cell>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef='localPackageName'>
    <mat-header-cell *matHeaderCellDef> Name</mat-header-cell>
    <mat-cell *matCellDef='let element'> {{element.localPackageName}} </mat-cell>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef='status'>
    <mat-header-cell *matHeaderCellDef> Status</mat-header-cell>
    <mat-cell *matCellDef='let element'>
      <mat-chip-list class='mat-chip-list-stacked' aria-label='Color selection'>
        <mat-chip color='accent' style='width: 105px; justify-content: center' [ngStyle]="{'background-color': element.status === 'ADDED' ? '#0066cc' : '#006400'}" selected>{{element.status}}</mat-chip>
      </mat-chip-list>
    </mat-cell>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef='actions'>
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef='let element'>
      <button mat-raised-button class='test'>Edit</button>
      <button *ngIf='element.status !== "PUBLISHED"' mat-raised-button class='test' color='primary'>Publish</button>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef='displayedColumns'></mat-header-row>
  <mat-row *matRowDef='let row; columns: displayedColumns;' class='pointer' [class.selected]='selection.isSelected(row)' (click)='selectHandler(row)' (change)='selectHandler(row)'>
  </mat-row>
</mat-table>
