import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as Material from './material-modules-list';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ...Material.AllModulesList
  ],
  exports: [
    ...Material.AllModulesList
  ]
})
export class MaterialModule {
}
