import { Injectable } from '@angular/core';
// TODO: add analytics to useradmin
import { AuthStoreService } from '@dialog-eservices-enablement/angular-components';
import { CountryLanguageService } from '@dialog-eservices-enablement/angular-components';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { fromEvent, Observable, Subject, Subscriber } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

/*
This is how the Adobe Launch script works:

Include the Adobe Launch script on your page
When the script finishes loading, it fetches its own configuration from Adobe
The script puts an object called "_satellite" on the window object. This object has a function _satellite.track().
When called, _satellite.track('pageLoad') looks up the event name ('pageLoad') in its configuration. It will only be in its configuration if someone has configured a custom call rule on the Adobe website beforehand with the same event name ('pageLoad').
If the event name is found in the configuration, _satellite.track('pageLoad') looks up window.digitalData and sends it off to Adobe. If the event name is not found in the configuration, no http call will be made.
 */

interface DigitalData {
  page: {
    eService: string;
    siteCountry: string;
    siteLanguage: string;
    pageName: string;
  };
  user: {
    loggedIn: boolean;
    id: string; // TODO Does it have to be hashed for privacy reasons?
  };
}

// Add future custom events to this type
// These event names have to be configured on the Adobe website as "Custom Call Rules"
type CustomAnalyticsEvent = 'pageLoad';

export interface SendData {
  digitalData: DigitalData;
  event: CustomAnalyticsEvent;
}

export interface PageLoadUpdate {
  pageName: string;
}

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  private pageLoaded$: Subject<void> = new Subject();
  private mutationObserver$ = (target: Node, config: MutationObserverInit = { childList: true }): Observable<MutationRecord> => {
    return new Observable((observer: Subscriber<MutationRecord>) => {
      const mutationObserver = new MutationObserver((mutations: MutationRecord[], instance: MutationObserver) => {
        mutations.forEach((mutation: MutationRecord) => {
          observer.next(mutation);
        });
      });
      mutationObserver.observe(target, config);
      return () => {
        mutationObserver.disconnect();
      };
    });
  };
  public constructor(private activatedRoute: ActivatedRoute, private router: Router, private authStoreService: AuthStoreService, private countryLanguageService: CountryLanguageService) {}

  public init(): void {
    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        // Get the deepest route e.g. dialog/de/de/groups/1 and check its data.trackWithAnalytics
        // property. Because we probably don't want to send an analytics event for user groups.
        let route = this.activatedRoute;
        while (route.firstChild) {
          route = route.firstChild;
        }
        this.pageLoaded$.next();
        if (route.snapshot.data.trackWithAnalytics) {
          // Remove leading slash '/e-services' -> 'e-services'
          const pageName = e.urlAfterRedirects.substring(1);
          this.pageLoad({ pageName });
        }
      }
    });
  }
  private pageLoad(pageLoadUpdate: PageLoadUpdate): void {
    const country = this.countryLanguageService.countryCode;
    const language = this.countryLanguageService.languageCode;

    this.sendData({
      event: 'pageLoad',
      digitalData: {
        page: {
          eService: 'LIAT Download',
          pageName: `DiaLog:LIAT Download:${country}:${language}:${pageLoadUpdate.pageName}`,
          siteCountry: country,
          siteLanguage: language
        },
        user: {
          loggedIn: this.authStoreService.isLoggedIn,
          id: this.authStoreService.isLoggedIn ? this.authStoreService.userProfile.trackingId : null
        }
      }
    });
  }

  private sendData(sendData: SendData): void {
    (window as any).digitalData = sendData.digitalData;
    const scriptHasLoaded = Boolean((window as any)._satellite);
    if (scriptHasLoaded) {
      (window as any)._satellite.track(sendData.event);
    } else {
      const script = document.getElementById('analytics-script') as HTMLScriptElement;
      if (script) {
        // a text/javascript type means the script is at least ready to run
        if (script.type === 'text/javascript') {
          script.addEventListener('load', () => {
            // When the script has loaded, it puts the _satellite object on the window object.
            // It then fetches its configuration. I think its safe to call _satellite.track() before
            // the fetching of the configuration is complete, because this sounds like the calls
            // will be queued until the configuration is available:
            // https://docs.adobe.com/content/help/en/launch/using/reference/client-side-info/asynchronous-deployment.html#considerations-to-asynchronous-deployment
            (window as any)._satellite.track(sendData.event);
          });
        } else {
          // handles text/plain cases: script element is there and loaded as text/plain because we don't have permissions
          // to run it until the user allows it via OneTrust
          this.mutationObserver$(document.head)
            .pipe(
              // discard previous subscription if there's a new page loaded (this ensures that only the current displayed page is sent)
              takeUntil(this.pageLoaded$),
              filter((record: MutationRecord) => record.addedNodes.length === 1),
              map((record: MutationRecord) => record.addedNodes.item(0) as Node),
              filter((element: Node) => (element as HTMLElement).getAttribute('id') === 'analytics-script'),
              switchMap((script: HTMLScriptElement) => fromEvent<Event>(script, 'load'))
            )
            .subscribe(() => {
              (window as any)._satellite.track(sendData.event);
            });
        }
      }
    }
  }
}
