import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OneSnackBarModule } from '@one/angular-kit/modal';
import { LearnMoreDialogModule } from '@dialog-eservices-enablement/angular-components';
import { RequestAccessDialogModule } from '@dialog-eservices-enablement/angular-components';
import { FeatureFlagModule } from '@dialog-eservices-enablement/angular-components';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { CookieService } from 'ngx-cookie-service';
import { AngularBoilerplateComponent } from './components/angular-boilerplate/angular-boilerplate.component';
import {MatTabsModule} from '@angular/material/tabs';
import { ChartComponent } from './chart/chart.component';
import { OneBarChartService } from '@one/angular-kit/chart';
import { OneBarChartModule } from '@one/angular-kit/chart';
import { BasicPieChartComponent } from './chart/pie-chart.component';



@NgModule({
  declarations: [AngularBoilerplateComponent, ChartComponent, BasicPieChartComponent],
  exports: [AngularBoilerplateComponent],
  imports: [CommonModule, MatCardModule, MatIconModule, MatButtonModule, OneSnackBarModule, LearnMoreDialogModule,
    RequestAccessDialogModule, FeatureFlagModule, MatTabsModule, OneBarChartModule],
  providers: [CookieService, OneBarChartService]
})
export class SampleFeatureModule {}
