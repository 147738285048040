import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dl-terms-and-conditions-modal',
  templateUrl: './terms-and-conditions-modal.component.html',
  styleUrls: ['./terms-and-conditions-modal.component.scss']
})
export class TermsAndConditionsModalComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
