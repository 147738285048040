import { ModuleWithProviders, NgModule } from '@angular/core';
import { LoginDialogModule } from '@dialog-eservices-enablement/angular-components';
import { FooterModule } from '@dialog-eservices-enablement/angular-components';
import { HeaderModule } from '@dialog-eservices-enablement/angular-components';
import { FeatureFlagModule } from '@dialog-eservices-enablement/angular-components';
import { loadingInterceptorProvider } from '@dialog-eservices-enablement/angular-components';
import { LoadingBarModule } from '@dialog-eservices-enablement/angular-components';
//import { CustomHttpInterceptorService } from '@dialog-eservices-enablement/angular-components';
import { AuthApiService } from '@dialog-eservices-enablement/angular-components';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AMPLIFYSERVICE, ENVIRONMENT } from '@dialog-eservices-enablement/angular-components';
import { appInitializerProvider } from './app-initializer/app-initializer.service';
import { environment } from '../../environments/environment';
import { APP_BASE_HREF, CommonModule, PlatformLocation } from '@angular/common';
import { RegionDialogComponent } from './components/region-dialog/region-dialog.component';
import { OneDialogModule } from '@one/angular-kit/modal';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { OneEditableDropdownModule } from '@one/angular-kit/form';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCardModule } from '@angular/material/card';
import { OneCardModule } from '@one/angular-kit/layout';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import Auth from '@aws-amplify/auth';
import { AmplifyModules } from 'aws-amplify-angular';
import { ModalService } from './services/modal.service';

@NgModule({
  declarations: [RegionDialogComponent],
  exports: [LoginDialogModule, FooterModule, HeaderModule, FeatureFlagModule, LoadingBarModule],
  imports: [
    HttpClientModule,
    LoginDialogModule,
    FooterModule,
    HeaderModule,
    FeatureFlagModule,
    LoadingBarModule,
    CommonModule,
    MatCardModule,
    OneCardModule,
    MatButtonModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatInputModule,
    OneDialogModule,
    ReactiveFormsModule,
    MatDialogModule,
    OneEditableDropdownModule,
    MatIconModule,
    MatAutocompleteModule,
    RouterModule
  ],
  providers: [ModalService]
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        { provide: ENVIRONMENT, useValue: environment },
        //{ provide: HTTP_INTERCEPTORS, useClass: CustomHttpInterceptorService, multi: true },
        {
          provide: AMPLIFYSERVICE,
          useFactory: () => {
            return AmplifyModules({
              Auth
            });
          }
        },
        {
          provide: APP_BASE_HREF,
          useFactory: (platformLocation: PlatformLocation) => platformLocation.getBaseHrefFromDOM(),
          deps: [PlatformLocation]
        },
        AuthApiService,
        appInitializerProvider,
        loadingInterceptorProvider
      ]
    };
  }
}
