import { NgModule } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { AppRouterModule } from './app-router.module';
import { AppComponent } from './app.component';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { CoreModule } from './core/core.module';
import { SampleFeatureModule } from './features/sample-feature/sample-feature.module';
import { SoftwareDownloadModule } from './features/software-download/software-download.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRouterModule,
    HammerModule,
    CoreModule.forRoot(),
    SampleFeatureModule,
    SoftwareDownloadModule
  ],
  providers: [
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2500 } },
    ...environment.providers
    /*{ provide: HTTP_INTERCEPTORS, useClass: FakeBackendInterceptor, multi: true }*/
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
