import { Injectable } from '@angular/core';
import { ComponentType } from '@angular/cdk/portal';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

@Injectable()
export class ModalService {
  dialogConfig = new MatDialogConfig();
  baseDialogClass = 'custom-dialog';

  constructor(public dialog: MatDialog) {
    this.dialogConfig.autoFocus = false;
    this.dialogConfig.position = { top: '30px' };
    this.dialogConfig.disableClose = true;
    this.dialogConfig.panelClass = this.baseDialogClass;
  }

  showModal<T, D, R>(
    modalComponent: ComponentType<T>,
    data?: D,
    dialogConfig?: MatDialogConfig
  ): MatDialogRef<T, R> {
    dialogConfig = { ...this.dialogConfig, ...dialogConfig };
    dialogConfig.data = data || {};
    return this.dialog.open<T>(modalComponent, dialogConfig);
  }
}
