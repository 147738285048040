import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TranslatedBundleGuard } from '@dialog-eservices-enablement/angular-components';
import { AngularBoilerplateComponent } from './features/sample-feature/components/angular-boilerplate/angular-boilerplate.component';
import { DashboardComponent} from './features/software-download/components';
import { DownloadInstructionPageComponent } from './features/software-download/components';

export const appRouterModule: Routes = [
  {
    path: '',
    redirectTo: '/software-download',
    pathMatch: 'full'
  },
  {
    path: 'app-enabler',
    component: AngularBoilerplateComponent,
    canActivate: [TranslatedBundleGuard],
    data: { trackWithAnalytics: true }
  },
  {
    path: 'software-download',
    component: DashboardComponent,
    canActivate: [TranslatedBundleGuard],
    data: { trackWithAnalytics: true }
  },
  {
    path: 'download-instructions',
    component: DownloadInstructionPageComponent,
    canActivate: [TranslatedBundleGuard],
    data: { trackWithAnalytics: true }
  },
  {
    path: '**',
    redirectTo: '/software-download',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRouterModule, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRouterModule {
}
