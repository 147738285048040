import { APP_INITIALIZER, Inject, Injectable, Injector } from '@angular/core';
import { DetermineBundleService } from '@dialog-eservices-enablement/angular-components';
import { AuthApiService } from '@dialog-eservices-enablement/angular-components';
import { RememberLocationService } from '@dialog-eservices-enablement/angular-components';
import { AuthStoreService } from '@dialog-eservices-enablement/angular-components';
import { SupplementalContentService } from '@dialog-eservices-enablement/angular-components';
import { forkJoin } from 'rxjs';
import { AffiliateService } from '@dialog-eservices-enablement/angular-components';
import { ChangeBundleService } from '@dialog-eservices-enablement/angular-components';
import { SessionStorageServiceReusableComponents } from '@dialog-eservices-enablement/angular-components';
import { ENVIRONMENT } from '@dialog-eservices-enablement/angular-components';
import { EnvironmentInterface } from '@dialog-eservices-enablement/angular-components';
import { HttpClient } from '@angular/common/http';

const IDP_SLO_FLAG = 'idp-logout';

@Injectable({
  providedIn: 'root'
})
export class AppInitializerService {
  public constructor(private sessionStorageService: SessionStorageServiceReusableComponents, private determineBundleService: DetermineBundleService, private changeBundleService: ChangeBundleService, private rememberLocationService: RememberLocationService, private authApiService: AuthApiService, private authStoreService: AuthStoreService, private supplementalContentService: SupplementalContentService, private affiliateService: AffiliateService, @Inject(ENVIRONMENT) private environment: EnvironmentInterface, private http: HttpClient) {}

  public initApp(): Promise<void> {
    this.checkQueryParams();
    /*
     * Since Congito does not support Idp initiated Logout we need to open an endpoint which would enable a logout without calling the idp logout endpoint
     */
    const isComingFromIdpInitiatedLogout = window.location.pathname.includes('idp-slo');
    if (isComingFromIdpInitiatedLogout) {
      return this.handleIdpSingleLogout();
    }
    if (this.determineBundleService.isDefaultBundle) {
      return this.handleDefaultBundle();
    } else {
      return this.handleTranslatedBundle();
    }
  }

  // When the user comes from the registration page, a query param is sent in the url telling us to not show the login dialog
  private checkQueryParams(): void {
    const fullQueryUrl = window.location.search;
    // analyse the query params and look for a parameter named login-popup
    if (fullQueryUrl.length > 0) {
      try {
        const removeQuestionMark = window.location.search.substring(1, window.location.search.length);
        const queryParamComponents = removeQuestionMark.split('&');
        const loginPopupQueryParam = queryParamComponents.find(component => component.includes('login-popup'));
        if (loginPopupQueryParam) {
          const loginPopupValue = loginPopupQueryParam.split('=')[1];
          if (loginPopupValue !== 'true') {
           this.sessionStorageService.preventLoginDialogViaQueryParam();
          }
        }
      } catch (e) {
        console.error('malformed url');
      }
    }
  }

  private handleIdpSingleLogout(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      return this.authApiService
        .getJwt()
        .then(_ => {
          localStorage.setItem(IDP_SLO_FLAG, 'true');
          this.authApiService.signOut();
          reject();
          return;
        })
        .catch(e => {
          console.log(e);
          window.location.href = '/';
          reject();
          return;
        });
    });
  }

  private handleDefaultBundle(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const isComingFromLogin = window.location.search.includes('?code=');
      if (isComingFromLogin) {
        this.authApiService.listenForAuthAfterExternalSignin().subscribe(x => {
          this.authApiService.getCurrentUser().then(userProfile => {
            const isLoggedIn = Boolean(userProfile);
            if (isLoggedIn) {
              const location = {
                countryCode: userProfile.countryCode,
                languageCode: userProfile.language
              };
              this.rememberLocationService.rememberLocation(location);
              this.changeBundleService.redirectToTranslatedBundle(location);
              reject();
              return;
            }
          });
        });
        return;
      }

      const isComingFromLogout = window.location.pathname.includes('logout');

      if (isComingFromLogout) {
        if (localStorage.getItem(IDP_SLO_FLAG) !== 'true') {
          this.authApiService.idpLogout();
          reject();
          return;
        } else {
          localStorage.removeItem(IDP_SLO_FLAG);
          window.location.href = '/';
          reject();
          return;
        }
      }

      const isLocationRemembered = this.rememberLocationService.isLocationRemembered();
      if (isLocationRemembered) {
        const { countryCode, languageCode } = this.rememberLocationService.getRememberedLocation();
        this.changeBundleService.redirectToTranslatedBundle({ countryCode, languageCode });
        reject();
        return;
      }

      forkJoin([this.supplementalContentService.setSupplementalContent(), this.affiliateService.setAffiliates() /* feature flags etc.*/]).subscribe(
        () => resolve(), // allow the app to start
        () => reject() // Prevent the app from starting
      );
    });
  }

  private handleTranslatedBundle(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const isComingFromLogin = window.location.search.includes('?code=');
      const initializeApp = () =>
        this.authApiService.getCurrentUser().then(userProfile => {
          const isLoggedIn = Boolean(userProfile);
          if (isLoggedIn) {
            this.authStoreService.userProfile = userProfile;
          }
          forkJoin([this.supplementalContentService.setSupplementalContent(), this.affiliateService.setAffiliates() /* feature flags etc.*/]).subscribe(
            () => {
              // Show the OneTrust banner, which is created with display: none; by the script
              const css = '#onetrust-consent-sdk {display: block !important}';
              const head = document.head || document.getElementsByTagName('head')[0];
              const style = document.createElement('style');
              head.appendChild(style);
              style.type = 'text/css';
              style.appendChild(document.createTextNode(css));
              // allow the app to start
              resolve();
            },
            () => reject() // Prevent the app from starting
          );
        });
      if (isComingFromLogin) {
        // check for translated bundle if coming from login because with ng serve the translated bundle is loaded directly and userProfile data not set yet
        this.authApiService.listenForAuthAfterExternalSignin().subscribe(initializeApp);
        return;
      } else {
        // not coming from login
        initializeApp();
      }
    });
  }
}

export const appInitializerProvider = {
  provide: APP_INITIALIZER,
  multi: true,
  useFactory: (appInitializerService: AppInitializerService) => () => appInitializerService.initApp(),
  deps: [AppInitializerService]
};
