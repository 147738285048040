<div class='terms-and-conditions-modal-container'>
  <div class='terms-and-conditions-modal__dialog-panel'>
    <h1 class='terms-and-conditions-modal__dialog-panel--title'><span>cobas® liat PCR System Software Downloads Terms & Conditions</span>
    </h1>
    <div class='terms-and-conditions-modal__text'>
      <div>This is a legal agreement between you, the end user, and Roche Diagnostics, the developer of the program
        ("cobas® liat PCR System"), regarding your use of this Software. By installing the Software, you agree to be
        bound by the terms of this agreement.
      </div>

      <div>1. GRANT OF LICENSE
        Roche Diagnostics hereby grants to you the right to install the Software on any computer, which is used to
        connect with one or more of the Roche Diagnostics products cobas® liat PCR System.
      </div>

      <div>2. COPYRIGHT
        The Software is owned by Roche Diagnostics and protected by copyright law and international copyright treaties.
        You may not remove or conceal any proprietary notices, labels or marks from the Software.
      </div>

      <div>
        3. RESTRICTIONS ON USE
        You may not, and you may not permit others to (a) reverse engineer, decompile, decode, decrypt, disassemble, or
        in any way derive source code from, the Software; (b) modify, distribute, or create derivative works of the
        Software; (c) copy (other than one back-up copy), distribute, publicly display, transmit, sell, rent, lease or
        otherwise exploit the Software.
      </div>
      <div>
        4. TERM
        This License is effective until terminated. You may terminate it at any time by destroying the Software,
        together with all copies thereof. This License will also terminate if you fail to comply with any term or
        condition of this Agreement. Upon such termination, you agree to destroy the Software, together with all copies
        thereof.
      </div>
      <div>
        5. NO OTHER WARRANTIES.
        Roche Diagnostics DOES NOT WARRANT THAT THE SOFTWARE IS ERROR FREE. Roche Diagnostics DISCLAIMS ALL OTHER
        WARRANTIES WITH RESPECT TO THE SOFTWARE, EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO IMPLIED
        WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT OF THIRD PARTY RIGHTS. SOME
        JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES OR LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY
        MAY LAST, OR THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATIONS OR
        EXCLUSIONS MAY NOT APPLY TO YOU. THIS WARRANTY GIVES YOU SPECIFIC LEGAL RIGHTS AND YOU MAY ALSO HAVE OTHER
        RIGHTS WHICH VARY FROM JURISDICTION TO JURISDICTION.
      </div>
      <div>
        6. SEVERABILITY.
        In the event of invalidity of any provision of this license, the parties agree that such invalidity shall not
        affect the validity of the remaining portions of this license.
      </div>
      <div>
        7. NO LIABILITY FOR CONSEQUENTIAL DAMAGES.
        IN NO EVENT SHALL Roche Diagnostics OR ITS SUPPLIERS BE LIABLE TO YOU FOR ANY CONSEQUENTIAL, SPECIAL, INCIDENTAL
        OR INDIRECT DAMAGES OF ANY KIND ARISING OUT OF THE DELIVERY, PERFORMANCE OR USE OF THE SOFTWARE, EVEN IF Roche
        Diagnostics HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT WILL Roche Diagnostics LIABILITY
        FOR ANY CLAIM, WHETHER IN CONTRACT, TORT OR ANY OTHER THEORY OF LIABILITY, EXCEED THE LICENSE FEE PAID BY YOU,
        IF ANY.
      </div>
      <div>
        ACKNOWLEDGMENT
        BY INSTALLING THE SOFTWARE, YOU ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND THE FORE GOING AND THAT YOU AGREE
        TO BE BOUND BY ITS TERMS AND CONDITIONS. YOU ALSO AGREE THAT THIS AGREEMENT IS THE COMPLETE AND EXCLUSIVE
        STATEMENT OF AGREEMENT BETWEEN THE PARTIES AND SUPERSEDES ALL PROPOSED OR PRIOR AGREEMENTS, ORAL OR WRITTEN, AND
        ANY OTHER COMMUNICATIONS BETWEEN THE PARTIES RELATING TO THE LICENSE DESCRIBED HEREIN.
      </div>
    </div>
    <div class='terms-and-conditions-modal__buttons mat-dialog-actions'>
      <button mat-stroked-button type='button' id='closeButton' color='primary' mat-dialog-close>Decline</button>
      <button mat-raised-button type='button' id='acceptButton' color='primary' [routerLink]="'/download-instructions'" mat-dialog-close>I Agree to the Terms & Conditions
      </button>
    </div>
  </div>
</div>
